import React from "react"
import SEO from "../../components/SEO"
import { Container, Row, Col } from "reactstrap"
import LegalSidebar from "../../components/LegalSidebar"

class LegalPage extends React.Component {

  render() {
    return (
      <>
        <SEO
          title="brillea - Legal Documents"
        />
        <Container className={"section_large"}>
          <Row>
            <Col md={{size:3}}>
              <LegalSidebar />
            </Col>
            <Col>
                <h1>Legal stuff</h1>
                <p>There are a few legal documents that are central to life at brillea -- to us, our customers, our prospects, our partners, and just plain old users of our website. To make it easy to find the information you’re looking for, we’ve assembled them all here under one roof and provided you with a quick rundown below of what you’ll find in each one.</p>
            </Col>
        </Row>
        </Container>
      </>
    )
  }
}
export default LegalPage
