import React from "react"
import {Link} from 'gatsby';
import { Nav, NavItem } from "reactstrap";

const LegalSidebar = () => {
  return (
      <>
      <h5>Legal</h5>
        <Nav className='legal_sidebar'>
            <NavItem>
                <Link className="nav-link" to={"/legal/cookie-policy/"}>Cookie Policy</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to={"/legal/privacy-policy/"}>Privacy Policy</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to={"/legal/terms-and-conditions/"}>brillea Terms &amp; Conditions</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to={"/legal/referral-terms-and-conditions/"}>Referral Terms &amp; Conditions</Link>
            </NavItem>
        </Nav>
    </>
  )
}
export default LegalSidebar;